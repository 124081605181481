<template>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper-moble" @click.stop>
      <div class="change-box">
        <h2 class="change-title">
          {{ step == 2 ? "绑定新号码" : "更换手机号" }}
        </h2>
        <div class="input-box">
          <span class="mobile-title">{{
            step == 2 ? "新手机号" : "当前手机号"
          }}</span>
          <input
            class="mobile-text"
            v-model="tel"
            maxlength="11"
            placeholder="请输入手机号"
            type="text"
          />
          <span class="code-title">短信验证码</span>
          <div class="code-box">
            <input
              class="code-text"
              v-model="code"
              maxlength="6"
              placeholder="请输入短信验证码"
              type="text"
            />
            <van-button
              @click="getCode"
              :disabled="isVertify"
              class="code-btn"
              type="primary"
            >
              {{ codeText }}
            </van-button>
          </div>
        </div>
        <div class="action-box">
          <van-button class="action cancel-btn" type="primary" @click="cancel">
            取消
          </van-button>
          <van-button class="action ok-btn" type="primary" @click="onOk">
            {{ step == 2 ? "确认提交" : "确认更换" }}
          </van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay, Button } from "vant";
import { sendPnoneCode, CheckPhoneCode, UpdateMobile } from "@/api/common";
import { mapState } from "vuex";
export default {
  name: "ChangeMobile",
  props: {
    value: {
      default: false,
    },
    step: {
      default: 1,
    },
    oldMobile: {
      default: "",
    },
  },
  components: {
    [Overlay.name]: Overlay,
    [Button.name]: Button,
  },
  computed: {
    ...mapState(["telReg"]),
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", false);
      },
    },
  },
  data() {
    return {
      tel: "",
      code: "",
      codeText: "获取验证码",
      num: 60,
      timer: null,
      isVertify: false,
    };
  },
  watch: {
    tel: function (val) {
      this.tel = val.replace(/\D/g, "");
    },
  },
  methods: {
    cancel() {
      this.tel = "";
      this.code = "";
      this.$emit("onCancel");
    },
    async onOk() {
      // this.$emit("onOk");
      if (!this.tel) {
        this.$toast("请输入手机号！");
        return;
      }
      if (!this.telReg.test(this.tel)) {
        this.$toast("手机号码格式不正确！");
        return;
      }
      if (!this.code) {
        this.$toast("请输入验证码！");
        return;
      }
      this.$loading("请稍等...");
      if (this.step == 1) {
        try {
          const res = await CheckPhoneCode({
            code: this.code,
            mobile: this.tel,
            type: "check",
          });
          this.$toast.clear();
          if (res && res.code == 0) {
            clearInterval(this.timer);
            this.$emit("onOk", 1, this.tel);
            this.codeText = "获取验证码";
            this.isVertify = false;
            this.tel = "";
            this.code = "";
          } else {
            this.$toast(res.message);
          }
        } catch (error) {
          this.$toast.clear();
        }
        return;
      }

      try {
        const res = await UpdateMobile({
          oldMobile: this.oldMobile,
          newMobile: this.tel,
          code: this.code,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.$toast.success("修改成功！");
          this.$emit("onOk", 2);
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
    // 获取验证码
    async getCode() {
      if (this.isVertify) return;
      if (!this.tel) {
        this.$toast("请输入手机号！");
        return;
      }
      if (!this.telReg.test(this.tel)) {
        this.$toast("手机号码格式不正确！");
        return;
      }
      this.$loading("请稍等...");
      try {
        const res = await sendPnoneCode({
          mobile: this.tel,
          type: this.step == 2 ? "updateMobile" : "check",
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.$toast.success("发送成功！");
          this.timer = setInterval(() => {
            if (this.num <= 0) {
              clearInterval(this.timer);
              this.num = 60;
              this.codeText = "获取验证码";
              this.isVertify = false;
            } else {
              this.num = this.num - 1;
              this.codeText = `${this.num}s`;
              this.isVertify = true;
            }
          }, 1000);
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper-moble {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .change-box {
    width: 5.9rem;
    height: 5.72rem;
    background: #ffffff;
    border-radius: 0.2rem;
    padding: 0 0.4rem;

    input {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      padding: 0 0.2rem;
      box-sizing: border-box;
      background: #fff;
    }
    input[type="text"] {
      -webkit-appearance: none;
    }

    .change-title {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-top: 0.32rem;
      text-align: center;
    }

    .input-box {
      display: flex;
      flex-direction: column;
      margin-top: 0.52rem;

      .mobile-title {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .mobile-text {
        height: 0.72rem;
        background: #ffffff;
        border-radius: 0.08rem;
        border: 0.02rem solid #eaf0f0;
        outline: none;
        margin-top: 0.08rem;
      }
      .code-title {
        margin-top: 0.28rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .code-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.08rem;

        .code-text {
          width: 3.2rem;
          height: 0.72rem;
          background: #ffffff;
          border-radius: 0.08rem;
          border: 0.02rem solid #eaf0f0;
          outline: none;
        }
        .code-btn {
          width: 1.76rem;
          height: 0.72rem;
          background: #ff4800;
          border-radius: 0.08rem;
          border: 0.02rem solid #ff4800;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          padding: 0;
        }
      }
    }
    .action-box {
      margin-top: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action {
        width: 2.4rem;
        height: 0.72rem;
        border-radius: 0.52rem;
        border: 0.02rem solid #e8e8e8;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
        background-color: #fff;
        padding: 0;

        &.ok-btn {
          background: #ff4800;
          color: #fff;
          border-color: #ff4800;
        }
      }
    }
  }
}
</style>